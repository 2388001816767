<template>
  <b-row>
    <b-col md="3">
      <b-list-group>
        <b-list-group-item>
          <b-input-group>
            <b-form-input style="border:0px" placeholder="Recherchez !" v-model="filters.search" @keyup="datatableInit()"></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" v-b-modal.new-sms><i class="fas fa-plus"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-list-group-item>
        <b-list-group-item v-for="(d,i) in datatable.data" :key="i" class="d-flex justify-content-between align-items-center" @click="openChat(d.id)" :active="chat.id === d.id">
          {{d.titre}} {{d.mobile}} <b-button size="sm" class="btn-icon" variant="danger" v-if="d.read === false"><i class="fas fa-exclamation"></i></b-button>
        </b-list-group-item>
      </b-list-group>
      <b-pagination class="mt-5" align="center" v-model="datatable.currentPage" @change="changePage" :total-rows="datatable.totalRow" :per-page="datatable.limitPage" aria-controls="itemList"></b-pagination>
    </b-col>
    <b-col md="9" v-if="chat.id != null">
      <b-card :title="chat.titre" id="chat">
        <div id="card-messages">
          <b-row v-for="(m,i) in messages" :key="i" class="mt-2">
            <b-col sm="6" v-if="m.is_user" class="p-3 text-right">
              <small>{{$store.back.timestampToDate(m.date_create)}}</small>
            </b-col>
            <b-col sm="6" :class="m.is_user ? 'is_user' : 'no_user'" class="p-3">
              {{m.message}}
            </b-col>
            <b-col sm="6" v-if="m.is_user === false" class="p-3 text-left">
              <small>{{$store.back.timestampToDate(m.date_create)}}</small>
            </b-col>
          </b-row>
        </div>
        <hr class="mt-5 my-4">
        <b-row>
          <b-col md="12">
            <b-form-group label="Votre message:">
              <b-form-textarea v-model="messageTmp" rows="3" max-rows="6"></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col md="12" class="text-center">
            <b-button variant="success" @click="sendMessage()">
              <i v-if="loaderSend === false" class="fas fa-paper-plane"></i>
              <i v-else class="fas fa-spinner fa-spin"></i>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-modal id="new-sms" ref="new_sms" title="Nouveau sms">
      <b-form-group label="Titre:">
        <b-form-input v-model="chatTmp.titre"></b-form-input>
      </b-form-group>
      <b-form-group label="Mobile:">
        <b-form-input v-model="chatTmp.mobile" @keyup="checkMobile()"></b-form-input>
      </b-form-group>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button variant="default" @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="primary" @click="createChat()">
          Sauvegarder
        </b-button>
      </template>
    </b-modal>
  </b-row>
</template>

<script>
export default {
  data(){
    return {
      loaderSend:false,
      params:{},
      datatable:{
        currentPage: 1,
        limitPage: 15,
        totalRow: 0,
        data: []
      },
      sort:{
        key: 'date_update',
        value: 'DESC'
      },
      filters:{
        "search":null
      },
      chat: {
        id:null
      },
      messages:[],
      messageTmp: null,
      chatTmp:{
        titre:null,
        mobile:"+33"
      }
    };
  },
  components: {
  },
  watch: {
  },
  beforeMount() {
    this.$store.back.ajax('/magic/all', null, (res) => {
      if(res.status === true){
        this.params = res.data;
      }
    });
  },
  methods: {
    datatableInit(){
      var params = {
        filters: this.filters,
        sort: this.sort,
        limit: this.datatable.limitPage,
        page: this.datatable.currentPage
      }
      this.$store.back.ajax('/chat/liste', params, (res) => {
        this.datatable = res;
      });
    },
    sortChanged(e){
      this.sort = {
        field: e.sortBy,
        value: e.sortDesc === true ? "DESC" : "ASC"
      };
      this.datatable.data = [];
      this.datatableInit();
    },
    changePage(e){
      this.datatable.currentPage = e;
      this.datatableInit();
    },
    openChat(id){
      this.$store.back.ajax('/chat/'+id, null, (res) => {
        if(res.status === true){
          this.chat = res.data.chat;
          this.messages = res.data.messages;
          setTimeout(() => {
            var objDiv = document.getElementById("card-messages");
            objDiv.scrollTop = objDiv.scrollHeight;
            this.datatableInit();
          }, 500);
        }
      })
    },
    sendMessage(){
      if(this.loaderSend === true){
        return false;
      }
      this.loaderSend = true;
      var params = {
        chat_id: this.chat.id,
        message: this.messageTmp
      };
      this.$store.back.ajax('/chat/send', params, (res) => {
        if(res.status === true){
          this.openChat(this.chat.id);
          this.messageTmp = null;
        }
        this.loaderSend = false;
      })
    },
    createChat(){
      this.$store.back.ajax('/chat/create', this.chatTmp, (res) => {
        if(res.status === true){
          this.datatableInit(true);
          this.$refs.new_sms.hide();
          this.chatTmp = {
            titre:null,
            mobile:"+33"
          }
          this.openChat(res.data.id)
        }
      })
    },
    checkMobile(){
      if(this.chatTmp.mobile === null){
        return false
      }
      if(this.chatTmp.mobile.indexOf('+33') != 0){
        this.chatTmp.mobile = '+33';
      }
      if(this.chatTmp.mobile.length === 4 && ['6','7'].includes(this.chatTmp.mobile[3]) === false){
        this.chatTmp.mobile = '+33';
      }
      if(this.chatTmp.mobile.length > 12){
        this.chatTmp.mobile = '+33';
      }
    }
  },
  computed: {
  },
  mounted() {
    this.datatableInit();
    if(this.$route.query.id != undefined){
      this.openChat(this.$route.query.id);
    }
    if(this.$route.query.q != undefined){
      var tmp = this.$route.query.q;
      tmp = JSON.parse(tmp);
      if(tmp.mobile != null){
        tmp.mobile = tmp.mobile.replace(" ","+");
      }
      this.chatTmp = tmp;
      this.$refs.new_sms.show();
    }
    setInterval(() => {
      this.datatableInit();
    },1000*30);
  }
};
</script>
<style>
#chat .is_user{
  background:#3699FF;
  color: white;
  border-radius: 8px;
}
#chat .no_user{
  background:#a5a6af;
  color: white;
  border-radius: 8px;
}
#card-messages{
  height: 400px;
  overflow: scroll;
  padding: 15px;
}
</style>
